import { THomeTimeControls } from '@pages/home/_types';
import { useApplicationContext } from '@application';
import { getLSItem } from '@utils/_local_storage';
import { getTimeControlTimes } from '@utils/timeControls/_getTimeControlTimes';
import { getTimeControlTypes } from '@utils/timeControls/_getTimeControlTypes';
import { useCallback, useMemo, useState } from 'react';
import { BoardType, ITimeControl } from '@types';
import { STORAGE_KEYS } from '../_constants';
import { useBotSelectionSectionContext } from '../context';

const getDefaultTimeControl = () => {
  const localStorageTimeControl = restoreTimeControlFromLS();

  return localStorageTimeControl;
};

const restoreTimeControlFromLS = () => {
  const timeControlFromLS = getLSItem(
    STORAGE_KEYS.BOT_SETTINGS_TIME_CONTROL
  ) as ITimeControl | null;

  if (!timeControlFromLS) return;

  return timeControlFromLS;
};

export const getTimeControlTimeInit = (
  timeControls?: THomeTimeControls,
  type?: string
) => {
  if (!timeControls || !type) return '';
  const times = timeControls[type as unknown as BoardType];
  return String(times[0]?.id);
};

export const useGameSettingsTimeControlsData = () => {
  const { localization: l } = useApplicationContext();

  const {
    state: { timeControls, gameSettingsTimeControlOrder },
  } = useBotSelectionSectionContext();

  const defaultTimeControl = getDefaultTimeControl();

  const [timeControlType, setTimeControlType] = useState(
    defaultTimeControl?.board_type_name ?? 'blitz'
  );

  const [timeControlTime, setTimeControlTime] = useState(
    defaultTimeControl?.id
      ? String(defaultTimeControl?.id)
      : getTimeControlTimeInit(timeControls, timeControlType)
  );

  const typesOptions = useMemo(
    () => getTimeControlTypes(l, gameSettingsTimeControlOrder),
    [gameSettingsTimeControlOrder, l]
  );

  const timesOptions = useMemo(
    () => getTimeControlTimes(l, timeControls, timeControlType),
    [l, timeControlType, timeControls]
  );

  const onTimeControlTypeChange = useCallback(
    (timeControlType: string) => {
      setTimeControlType(timeControlType);
      setTimeControlTime(getTimeControlTimeInit(timeControls, timeControlType));
    },
    [timeControls]
  );

  const onTimeControlTimeChange = useCallback((value: string) => {
    setTimeControlTime(value);
  }, []);

  const resetTimeControls = useCallback(() => {
    setTimeControlType(defaultTimeControl?.board_type_name ?? 'blitz');
    setTimeControlTime(getTimeControlTimeInit(timeControls, timeControlType));
  }, [defaultTimeControl?.board_type_name, timeControlType, timeControls]);

  return {
    timeControls,

    timeControlTime,
    timeControlType,

    onTimeControlTimeChange,
    onTimeControlTypeChange,

    typesOptions,
    timesOptions,

    resetTimeControls,
  };
};
