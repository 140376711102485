import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { AccountTypeInfo, eAccessLevel } from '@types';
import { useFideIdStatus } from './_useFideIdStatus.hook';

export const useAccountType = (): AccountTypeInfo => {
  const userData = useUserDataStore('data');
  const logged = useAuthStore('logged');
  const fideIdStatus = useFideIdStatus();

  const accessLevel = userData?.access_level;

  if (!logged) return ['anonymous', fideIdStatus];
  if (accessLevel === eAccessLevel.PRO) return ['subscribed', fideIdStatus];
  return ['free', fideIdStatus];
};
