import { locales } from '@constants';
import { LocaleType } from '@types';

export type SeoParamsImage = Readonly<{
  url: string;
  alt?: string;
  width?: number;
  height?: number;
  type?: string;
}>;

export type SeoParams = Readonly<{
  route?: string;
  title: string;
  description?: string;
  locale: LocaleType;
  images?: SeoParamsImage[];
  keywords?: string;
}>;

export function getSeo(params: SeoParams) {
  const { route, title, description, locale, images, keywords } = params;

  const url = `${process.env.NEXT_PUBLIC_GAME_URL}${
    locale === LocaleType.EN ? '' : `/${locale}`
  }${route}`;

  const languageAlternates = [
    ...locales.map((item) => ({
      hrefLang: item,
      href: `${process.env.NEXT_PUBLIC_GAME_URL}${
        item === LocaleType.EN ? '' : `/${item}`
      }${route}`,
    })),
    {
      hrefLang: 'x-default',
      href: `${process.env.NEXT_PUBLIC_GAME_URL}${route}`,
    },
  ];

  return {
    title,
    description,
    openGraph: {
      type: 'website',
      siteName: 'FIDE Online Arena',
      url,
      title,
      description,
      locale,
      images: images?.map((image) => ({
        ...image,
        alt: image.alt || title,
      })),
    },
    canonical: url,
    languageAlternates,
    twitter: {
      cardType: 'summary_large_image',
      site: '@theworldchess',
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: `chess, chess online, online chess, chess game, play chess, play chess online, World Chess, online chess tournament, online chess tournaments for money, online chess tournaments with cash prizes${
          keywords ? `, ${keywords}` : ''
        }`,
      },
    ],
  };
}
