import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useBotSelectionSectionContext } from '../context';

import { useBotSelectionSettingsPopupData } from './useBotSelectionSettingsPopupData.hook';
import { GameSettings } from '../_types';
import { capitalizeString } from 'shared/helpers/_common';
import { ILocalization } from '@types';
import { useApplicationContext } from '@application';

const getDescription = (botGameSettings: GameSettings, l: ILocalization) => {
  return `${
    l?.main_page?.game?.any_bot_will_play ?? 'Any bot will play'
  } ${capitalizeString(botGameSettings.timeControl?.typeText ?? '')} ${
    botGameSettings.timeControl?.timeText
  }, ${botGameSettings.playerSide} ${l?.main_page?.game?.side ?? 'side'}`;
};

export const useBotSelectionHeaderData = () => {
  const {
    state: { data },
  } = useBotSelectionSectionContext();

  const { localization: l } = useApplicationContext();

  const { botGameSettings, settingsPopup, openSettingsPopup } =
    useBotSelectionSettingsPopupData(data);

  const onChangeSettingsClick = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      openSettingsPopup();
    },
    [openSettingsPopup]
  );

  const [description, setDescription] = useState(' ');

  const header = useMemo(
    () => ({
      title: data?.header.title,
      description,
      link: {
        title: l?.common?.change_settings,
        href: '#',
        onClick: onChangeSettingsClick,
      },
    }),
    [
      data?.header.title,
      description,
      l?.common?.change_settings,
      onChangeSettingsClick,
    ]
  );

  useEffect(() => {
    setDescription(getDescription(botGameSettings, l));
  }, [botGameSettings, l]);

  return { header, settingsPopup, botGameSettings };
};
